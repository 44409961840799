*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 12px;
}

@media (min-width: 1024px) {
  html {
    font-size: 14px;
  }

  .search-box {
    max-width: 250px;
  }

  .featured-news {
    max-width: 250px;
    min-width: 250px;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.tab-content {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(38, 45, 54, var(--tw-border-opacity));
  border-radius: .5rem;
  padding: .5rem;
}

:is(.dark .tab-content) {
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}

@media (min-width: 1024px) {
  .tab-content {
    border-radius: 1.5rem;
    padding: .75rem;
  }
}

.tab {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(19, 25, 33, var(--tw-bg-opacity));
  text-align: center;
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-weight: 500;
}

:is(.dark .tab) {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.tab.active {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 149, 233, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(231, 243, 255, var(--tw-text-opacity));
}

:is(.dark .tab.active) {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 149, 233, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(231, 243, 255, var(--tw-text-opacity));
}

.btn-list .active {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 149, 233, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(231, 243, 255, var(--tw-text-opacity));
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.\!relative {
  position: relative !important;
}

.relative {
  position: relative;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-1 {
  bottom: -.25rem;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-bottom-3 {
  bottom: -.75rem;
}

.-left-1 {
  left: -.25rem;
}

.-left-2 {
  left: -.5rem;
}

.-left-3 {
  left: -.75rem;
}

.-right-2 {
  right: -.5rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-3 {
  top: -.75rem;
}

.-top-4 {
  top: -1rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[56px\] {
  bottom: 56px;
}

.bottom-\[5px\] {
  bottom: 5px;
}

.bottom-\[65px\] {
  bottom: 65px;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-2 {
  left: .5rem;
}

.left-2\.5 {
  left: .625rem;
}

.left-\[15px\] {
  left: 15px;
}

.left-\[40px\] {
  left: 40px;
}

.left-\[50\%\] {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-\[12px\] {
  right: 12px;
}

.right-\[15px\] {
  right: 15px;
}

.right-\[5px\] {
  right: 5px;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-1\/3 {
  top: 33.3333%;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-\[10\%\] {
  top: 10%;
}

.top-\[10px\] {
  top: 10px;
}

.top-\[17px\] {
  top: 17px;
}

.top-\[5px\] {
  top: 5px;
}

.isolate {
  isolation: isolate;
}

.z-10 {
  z-index: 10;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clear-both {
  clear: both;
}

.-m-1 {
  margin: -.25rem;
}

.-m-1\.5 {
  margin: -.375rem;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-2\.5 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.mx-\[5px\] {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.box-border {
  box-sizing: border-box;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.\!block {
  display: block !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.\!flex {
  display: flex !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-1 {
  height: .25rem;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[140px\] {
  height: 140px;
}

.h-\[14px\] {
  height: 14px;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[5px\] {
  height: 5px;
}

.h-\[74px\] {
  height: 74px;
}

.h-\[80\%\] {
  height: 80%;
}

.h-\[80px\] {
  height: 80px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.max-h-14 {
  max-height: 3.5rem;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-\[140px\] {
  max-height: 140px;
}

.max-h-\[20px\] {
  max-height: 20px;
}

.max-h-\[28px\] {
  max-height: 28px;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.max-h-\[calc\(100vh_-_55vh\)\] {
  max-height: 45vh;
}

.max-h-full {
  max-height: 100%;
}

.min-h-\[140px\] {
  min-height: 140px;
}

.min-h-\[20px\] {
  min-height: 20px;
}

.min-h-\[300px\] {
  min-height: 300px;
}

.min-h-\[38px\] {
  min-height: 38px;
}

.min-h-\[500px\] {
  min-height: 500px;
}

.min-h-\[65px\] {
  min-height: 65px;
}

.min-h-\[75vh\] {
  min-height: 75vh;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-4 {
  width: 1rem !important;
}

.\!w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.\!w-full {
  width: 100% !important;
}

.w-1 {
  width: .25rem;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[130px\] {
  width: 130px;
}

.w-\[14px\] {
  width: 14px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[170px\] {
  width: 170px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[256px\] {
  width: 256px;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[35px\] {
  width: 35px;
}

.w-\[36px\] {
  width: 36px;
}

.w-\[3rem\] {
  width: 3rem;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[5px\] {
  width: 5px;
}

.w-\[64\%\] {
  width: 64%;
}

.w-\[80px\] {
  width: 80px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[125px\] {
  min-width: 125px;
}

.min-w-\[130px\] {
  min-width: 130px;
}

.min-w-\[170px\] {
  min-width: 170px;
}

.min-w-\[25px\] {
  min-width: 25px;
}

.min-w-\[30px\] {
  min-width: 30px;
}

.min-w-\[34px\] {
  min-width: 34px;
}

.min-w-\[350px\] {
  min-width: 350px;
}

.min-w-\[3rem\] {
  min-width: 3rem;
}

.min-w-\[50px\] {
  min-width: 50px;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[170px\] {
  max-width: 170px;
}

.max-w-\[172px\] {
  max-width: 172px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-\[30px\] {
  max-width: 30px;
}

.max-w-\[350px\] {
  max-width: 350px;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-max {
  max-width: -webkit-max-content;
  max-width: max-content;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.grow-\[4\] {
  flex-grow: 4;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-0 {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.origin-bottom-right {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.origin-center {
  -webkit-transform-origin: center;
  transform-origin: center;
}

.origin-top-left {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-full {
  --tw-translate-y: -100%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.animate-spin {
  -webkit-animation: 1s linear infinite spin;
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-help {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.columns-2 {
  columns: 2;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  align-content: center;
  justify-content: center;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-center {
  justify-items: center;
}

.gap-4 {
  gap: 1rem;
}

.gap-px {
  gap: 1px;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[3px\] {
  border-radius: 3px;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-br-\[5px\] {
  border-bottom-right-radius: 5px;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tr-\[5px\] {
  border-top-right-radius: 5px;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-x-2 {
  border-left-width: 2px;
  border-right-width: 2px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-none {
  border-style: none;
}

.border-\[\#181818\] {
  --tw-border-opacity: 1;
  border-color: rgba(24, 24, 24, var(--tw-border-opacity));
}

.border-\[\#1F262D\] {
  --tw-border-opacity: 1;
  border-color: rgba(31, 38, 45, var(--tw-border-opacity));
}

.border-\[\#222\] {
  --tw-border-opacity: 1;
  border-color: rgba(34, 34, 34, var(--tw-border-opacity));
}

.border-\[\#96a772\] {
  --tw-border-opacity: 1;
  border-color: rgba(150, 167, 114, var(--tw-border-opacity));
}

.border-\[\#fdfdfd\] {
  --tw-border-opacity: 1;
  border-color: rgba(253, 253, 253, var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgba(132, 143, 151, var(--tw-border-opacity));
}

.border-primary-darker {
  --tw-border-opacity: 1;
  border-color: rgba(38, 45, 54, var(--tw-border-opacity));
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.bg-\[\#181818\] {
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 24, var(--tw-bg-opacity));
}

.bg-\[\#23DF8C\], .bg-\[\#23df8c\] {
  --tw-bg-opacity: 1;
  background-color: rgba(35, 223, 140, var(--tw-bg-opacity));
}

.bg-\[\#333\] {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--tw-bg-opacity));
}

.bg-\[\#6002ee\] {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 2, 238, var(--tw-bg-opacity));
}

.bg-\[\#FEE900\] {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 233, 0, var(--tw-bg-opacity));
}

.bg-\[\#FF5555\] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 85, 85, var(--tw-bg-opacity));
}

.bg-\[\#aaa\] {
  --tw-bg-opacity: 1;
  background-color: rgba(170, 170, 170, var(--tw-bg-opacity));
}

.bg-\[\#fdfdfd\] {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity));
}

.bg-\[\#ff6b00\] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 107, 0, var(--tw-bg-opacity));
}

.bg-\[rgb\(0_0_0_\/_50\%\)\] {
  background-color: rgba(0, 0, 0, .5);
}

.bg-accent {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 149, 233, var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}

.bg-hsla-0-0-4-0\.5 {
  background-color: rgba(10, 10, 10, .5);
}

.bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(54, 83, 20, var(--tw-bg-opacity));
}

.bg-main-bg {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 17, 22, var(--tw-bg-opacity));
}

.bg-main-bg-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(19, 25, 33, var(--tw-bg-opacity));
}

.bg-primary-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(231, 243, 255, var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-white\/30 {
  background-color: rgba(255, 255, 255, .3);
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(202, 138, 4, var(--tw-bg-opacity));
}

.bg-\[linear-gradient\(180deg\,hsla\(0\,0\%\,4\%\,0\)\,\#111\)\] {
  background-image: linear-gradient(rgba(10, 10, 10, 0), #111);
}

.bg-none {
  background-image: none;
}

.fill-\[\#5B6874\] {
  fill: #5b6874;
}

.fill-accent {
  fill: #0095e9;
}

.fill-black {
  fill: #000;
}

.fill-primary {
  fill: #848f97;
}

.fill-primary-lighter {
  fill: #e7f3ff;
}

.stroke-accent {
  stroke: #0095e9;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-top {
  object-position: top;
}

.\!p-0 {
  padding: 0 !important;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-\[3px\] {
  padding: 3px;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.\!pb-0 {
  padding-bottom: 0 !important;
}

.\!pb-3 {
  padding-bottom: .75rem !important;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pl-\[20px\] {
  padding-left: 20px;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-start:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.text-start:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.text-start:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.text-end:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: right;
}

.text-end:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.text-end:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.\!text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[9px\] {
  font-size: 9px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-\[\#111\] {
  --tw-text-opacity: 1;
  color: rgba(17, 17, 17, var(--tw-text-opacity));
}

.text-\[\#AAAAAA\], .text-\[\#aaa\] {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity));
}

.text-\[\#fdfdfd\] {
  --tw-text-opacity: 1;
  color: rgba(253, 253, 253, var(--tw-text-opacity));
}

.text-\[\#ff6b00\] {
  --tw-text-opacity: 1;
  color: rgba(255, 107, 0, var(--tw-text-opacity));
}

.text-accent {
  --tw-text-opacity: 1;
  color: rgba(0, 149, 233, var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.text-main-bg {
  --tw-text-opacity: 1;
  color: rgba(13, 17, 22, var(--tw-text-opacity));
}

.text-main-bg-lighter {
  --tw-text-opacity: 1;
  color: rgba(19, 25, 33, var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgba(132, 143, 151, var(--tw-text-opacity));
}

.text-primary-darker {
  --tw-text-opacity: 1;
  color: rgba(38, 45, 54, var(--tw-text-opacity));
}

.text-primary-lighter {
  --tw-text-opacity: 1;
  color: rgba(231, 243, 255, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-25 {
  opacity: .25;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}

.ring-gray-900\/5 {
  --tw-ring-color: rgba(17, 24, 39, .05);
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter-none {
  -webkit-filter: none;
  filter: none;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.light {
  background: #fff;
}

.nav-item {
  flex-direction: column;
  padding-bottom: 1.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1rem;
  display: flex;
}

@media (min-width: 1024px) {
  .nav-item {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.nav-item svg {
  height: 1.5rem;
  width: 1.5rem;
  fill: #aaa;
}

.nav-item svg.nav-large-icon {
  height: 2.5rem;
  width: 2.5rem;
  fill: #aaa;
}

.nav-item .active-line {
  height: .5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  margin-bottom: 1.25rem;
}

.nav-item.menu {
  --tw-text-opacity: 1;
  color: rgba(231, 243, 255, var(--tw-text-opacity));
  flex-direction: column;
  padding-bottom: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  display: flex;
}

:is(.dark .nav-item.menu) {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.nav-item.menu svg {
  fill: #e7f3ff;
}

:is(.dark .nav-item.menu svg) {
  fill: #000;
}

.nav-item.menu .active-line {
  height: .5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  margin-bottom: 1.25rem;
}

.nav-item.active {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 149, 233, var(--tw-text-opacity)) !important;
}

.nav-item.active svg {
  fill: #0095e9;
}

.nav-item.active .active-line {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 149, 233, var(--tw-bg-opacity));
}

.sp-item-hover {
  width: 100%;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(19, 25, 33, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(231, 243, 255, var(--tw-text-opacity));
  border-radius: .5rem;
  justify-content: space-between;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

.sp-item-hover:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--tw-bg-opacity));
}

:is(.dark .sp-item-hover) {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.sp-item-hover-light {
  width: 100%;
  cursor: pointer;
  border-radius: .5rem;
  justify-content: space-between;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

.sp-item-hover-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--tw-bg-opacity));
}

.sp-item-hover-light {
  color: "#000";
  background-color: #fff;
}

.sp-item {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(19, 25, 33, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(231, 243, 255, var(--tw-text-opacity));
  border-radius: .5rem;
  justify-content: space-between;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

:is(.dark .sp-item) {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.score {
  height: 4rem;
  min-width: 2rem;
  --tw-bg-opacity: 1;
  background-color: rgba(38, 45, 54, var(--tw-bg-opacity));
  border-radius: .5rem;
  flex-direction: column;
  order: 9999;
  justify-content: center;
  align-items: center;
  margin-left: .5rem;
  margin-right: .5rem;
  padding: .25rem .75rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1rem;
  display: flex;
  position: absolute;
  right: 0;
}

:is(.dark .score) {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .score {
    height: auto;
    min-width: 5rem;
    flex-direction: row;
    order: 0;
    margin-left: .75rem;
    margin-right: .75rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    position: relative;
  }
}

.score-full-nomargin {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 45, 54, var(--tw-bg-opacity));
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .625rem .5rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1rem;
  display: flex;
}

@media (min-width: 640px) {
  .score-full-nomargin {
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.tab-detail {
  white-space: nowrap;
  flex-direction: column;
  padding-bottom: 1.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  display: flex;
}

@media (min-width: 1024px) {
  .tab-detail {
    padding-bottom: 1.25rem;
  }
}

.tab-detail .active-line {
  height: .5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  margin-bottom: .75rem;
}

@media (min-width: 1024px) {
  .tab-detail .active-line {
    margin-bottom: 1rem;
  }
}

.tab-detail.active {
  --tw-text-opacity: 1;
  color: rgba(0, 149, 233, var(--tw-text-opacity));
}

.tab-detail.active .active-line {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 149, 233, var(--tw-bg-opacity));
}

.sp-item-time {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(19, 25, 33, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(231, 243, 255, var(--tw-text-opacity));
  border-radius: .5rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

:is(.dark .sp-item-time) {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  .sp-item-time {
    padding: 1.5rem;
  }
}

.score-time {
  height: 4rem;
  width: 2.5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(38, 45, 54, var(--tw-bg-opacity));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1rem;
  display: flex;
}

:is(.dark .score-time) {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 163, 184, var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .score-time {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.score-time-start {
  height: 1rem;
  width: 2.5rem;
  --tw-bg-opacity: 1;
  background-color: rgba(38, 45, 54, var(--tw-bg-opacity));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1rem;
  display: flex;
}

:is(.dark .score-time-start) {
  --tw-bg-opacity: 1;
  background-color: rgba(148, 163, 184, var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .score-time-start {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.minute {
  height: 2rem;
  width: 2rem;
  --tw-bg-opacity: 1;
  background-color: rgba(13, 17, 22, var(--tw-bg-opacity));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  line-height: 1rem;
  display: flex;
}

:is(.dark .minute) {
  --tw-bg-opacity: 1;
  background-color: rgba(100, 116, 139, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(241, 245, 249, var(--tw-text-opacity));
}

.match-time {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(38, 45, 54, var(--tw-border-opacity));
  border-radius: .375rem;
  flex-direction: column;
  align-items: center;
  padding: .125rem 2rem;
  display: flex;
}

:is(.dark .match-time) {
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}

.action {
  height: 2.5rem;
  max-width: 100%;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(38, 45, 54, var(--tw-border-opacity));
  border-radius: .375rem;
  align-items: center;
  padding-left: .75rem;
  padding-right: .75rem;
  display: flex;
  overflow: hidden;
}

:is(.dark .action) {
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}

.label-check {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 143, 151, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(231, 243, 255, var(--tw-text-opacity));
  border-radius: .25rem;
  align-items: center;
  padding: .125rem .25rem;
  font-size: .75rem;
  line-height: 1rem;
  display: flex;
}

:is(.dark .label-check) {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.bg-bottom-shadow:after {
  height: 70px;
  width: 250px;
  --tw-content: "";
  content: var(--tw-content);
  background: linear-gradient(rgba(10, 10, 10, 0) 0, #111) no-repeat;
  position: absolute;
  top: 70px;
  left: 5px;
}

.ellipse-content {
  width: 100%;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

#scrollbar::-webkit-scrollbar-track {
  height: 3px;
  --tw-bg-opacity: 1;
  background-color: rgba(13, 17, 22, var(--tw-bg-opacity));
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

#scrollbar::-webkit-scrollbar {
  height: 3px;
  --tw-bg-opacity: 1;
  background-color: rgba(13, 17, 22, var(--tw-bg-opacity));
}

@media (min-width: 768px) {
  #scrollbar::-webkit-scrollbar {
    height: 8px;
  }
}

#scrollbar::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 73, 75, var(--tw-bg-opacity));
  border-radius: .5rem;
}

.btn-actived {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 34, 34, var(--tw-bg-opacity));
}

.btn-actived a {
  --tw-text-opacity: 1;
  color: rgba(0, 149, 233, var(--tw-text-opacity));
}

.swiper-button-disabled {
  display: none !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  color: #fff;
  font-size: 12px !important;
}

.imagecontainer table {
  width: 100%;
}

.news-wrapper .swiper:after, .news-wrapper .swiper:before {
  z-index: 5;
  content: "";
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
}

.news-wrapper .swiper:after {
  background-image: linear-gradient(270deg, #111, rgba(10, 10, 10, 0));
  right: 0;
}

:is(.dark .news-wrapper .swiper):after {
  background-image: none;
}

.news-wrapper .swiper:before {
  background-image: linear-gradient(90deg, #111, rgba(10, 10, 10, 0));
  left: 0;
}

:is(.dark .news-wrapper .swiper):before {
  background-image: none;
}

.news-content img {
  border-radius: .75rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.news-content p {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.news-content h2 {
  --tw-text-opacity: 1;
  color: rgba(253, 253, 253, var(--tw-text-opacity));
  margin-top: .75rem;
  font-size: 18px;
  font-style: italic;
  font-weight: 700;
}

:is(.dark .news-content h2) {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.news-content .summary {
  margin-bottom: .5rem;
  font-style: italic;
}

.news-content .imagecontainer, .news-content .wp-block-image, .news-content .image {
  text-align: center;
  font-style: italic;
}

.news-content .sapo_detail {
  font-style: italic;
  font-weight: 700;
}

.news-content .expNoEdit {
  text-align: center;
  font-style: italic;
}

.virtuoso-grid-item {
  width: 100%;
  display: inline-block;
}

@media (min-width: 768px) {
  .virtuoso-grid-item {
    width: 50%;
  }
}

figcaption.e-caption.u-meta.e-caption--has-separator {
  text-align: center;
  margin-bottom: 1.25rem;
  font-style: italic;
  line-height: 1;
  display: block;
}

figcaption.e-caption.u-meta.e-caption--has-separator span {
  line-height: 15px;
}

figcaption.e-caption.u-meta.e-caption--has-separator p {
  margin: 0;
}

figcaption.e-caption.u-meta.e-caption--has-separator .e-caption__credit {
  line-height: 5px !important;
}

.news-img > span.lazy-load-image-background.blur.lazy-load-image-loaded, .news-slider > a > span.lazy-load-image-background.blur.lazy-load-image-loaded {
  flex: 0 0 130px;
  height: 100% !important;
  width: auto !important;
  display: block !important;
}

.highlights-img > span.lazy-load-image-background.blur {
  flex: 0 0 250px;
  height: 100% !important;
  width: auto !important;
  display: block !important;
}

.highlight-related > .lazy-load-image-background.blur.lazy-load-image-loaded {
  height: 100%;
  width: 100%;
}

.highlight-related > .lazy-load-image-background.blur img, .highlights-img span.lazy-load-image-background.blur img, .news-related > .lazy-load-image-background.blur img {
  width: 100% !important;
}

.news-related > .lazy-load-image-background.blur.lazy-load-image-loaded {
  height: 100%;
  width: 100%;
}

.featured-news-item .lazy-load-image-background.blur {
  width: 100% !important;
}

.show-on-hover {
  display: block;
}

.play-icon-animation {
  background-image: linear-gradient(125deg, #da9413, #2196f3);
  background-size: 400%;
  -webkit-animation: 1s infinite bganimation;
  animation: 1s infinite bganimation;
}

#bottom-menu {
  z-index: 20;
  width: 100%;
  cursor: pointer;
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgba(34, 34, 34, var(--tw-border-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity));
  flex-direction: row;
  margin: auto;
  text-decoration-line: none;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

:is(.dark #bottom-menu) {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 216, var(--tw-border-opacity));
}

#bottom-menu .nav-item {
  width: 25vw;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  flex-direction: column;
  margin: -1px auto auto;
  text-decoration-line: none;
  display: flex;
}

@-webkit-keyframes bganimation {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@keyframes bganimation {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

@media (min-width: 640px) {
  .sm\:tab-content {
    --tw-border-opacity: 1;
    border-width: 1px;
    border-color: rgba(38, 45, 54, var(--tw-border-opacity));
    border-radius: .5rem;
    padding: .5rem;
  }

  :is(.dark .sm\:tab-content) {
    --tw-border-opacity: 1;
    border-color: rgba(228, 228, 231, var(--tw-border-opacity));
  }

  @media (min-width: 1024px) {
    .sm\:tab-content {
      border-radius: 1.5rem;
      padding: .75rem;
    }
  }
}

@media (min-width: 768px) {
  .md\:tab-content {
    --tw-border-opacity: 1;
    border-width: 1px;
    border-color: rgba(38, 45, 54, var(--tw-border-opacity));
    border-radius: .5rem;
    padding: .5rem;
  }

  :is(.dark .md\:tab-content) {
    --tw-border-opacity: 1;
    border-color: rgba(228, 228, 231, var(--tw-border-opacity));
  }

  @media (min-width: 1024px) {
    .md\:tab-content {
      border-radius: 1.5rem;
      padding: .75rem;
    }
  }
}

.placeholder\:font-medium::-webkit-input-placeholder {
  font-weight: 500;
}

.placeholder\:font-medium::placeholder {
  font-weight: 500;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:h-12:before {
  content: var(--tw-content);
  height: 3rem;
}

.before\:h-16:before {
  content: var(--tw-content);
  height: 4rem;
}

.before\:h-3\/5:before {
  content: var(--tw-content);
  height: 60%;
}

.before\:h-full:before {
  content: var(--tw-content);
  height: 100%;
}

.before\:origin-center:before {
  content: var(--tw-content);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rounded-r-lg:before {
  content: var(--tw-content);
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.before\:border-l-4:before {
  content: var(--tw-content);
  border-left-width: 4px;
}

.before\:border-l-8:before {
  content: var(--tw-content);
  border-left-width: 8px;
}

.before\:border-orange-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgba(249, 115, 22, var(--tw-border-opacity));
}

.before\:border-transparent:before {
  content: var(--tw-content);
  border-color: rgba(0, 0, 0, 0);
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:left-\[2px\]:after {
  content: var(--tw-content);
  left: 2px;
}

.after\:top-\[2px\]:after {
  content: var(--tw-content);
  top: 2px;
}

.after\:h-4:after {
  content: var(--tw-content);
  height: 1rem;
}

.after\:w-4:after {
  content: var(--tw-content);
  width: 1rem;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border:after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-gray-300:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:rounded-l-lg:first-child {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.first\:border-none:first-child {
  border-style: none;
}

.last\:rounded-r-lg:last-child {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.last\:border-none:last-child {
  border-style: none;
}

.hover\:border-transparent:hover {
  border-color: rgba(0, 0, 0, 0);
}

.hover\:bg-\[\#333\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 51, 51, var(--tw-bg-opacity));
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-accent:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 149, 233, var(--tw-ring-opacity));
}

.focus-visible\:border-indigo-500:focus-visible {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus-visible\:ring-white:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.focus-visible\:ring-opacity-75:focus-visible {
  --tw-ring-opacity: .75;
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:ring-offset-orange-300:focus-visible {
  --tw-ring-offset-color: #fdba74;
}

.group:hover .group-hover\:bg-white\/50 {
  background-color: rgba(255, 255, 255, .5);
}

.group:focus .group-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.group:focus .group-focus\:ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.group:focus .group-focus\:ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.peer:checked ~ .peer-checked\:bg-accent {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 149, 233, var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:border-white:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.peer:focus ~ .peer-focus\:ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.peer:focus ~ .peer-focus\:ring-accent {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 149, 233, var(--tw-ring-opacity));
}

:is(.dark .dark\:border-accent) {
  --tw-border-opacity: 1;
  border-color: rgba(0, 149, 233, var(--tw-border-opacity));
}

:is(.dark .dark\:border-primary) {
  --tw-border-opacity: 1;
  border-color: rgba(132, 143, 151, var(--tw-border-opacity));
}

:is(.dark .dark\:border-zinc-200) {
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}

:is(.dark .dark\:border-zinc-300) {
  --tw-border-opacity: 1;
  border-color: rgba(212, 212, 216, var(--tw-border-opacity));
}

:is(.dark .dark\:bg-\[\#ccc\]) {
  --tw-bg-opacity: 1;
  background-color: rgba(204, 204, 204, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-gray-800\/30) {
  background-color: rgba(31, 41, 55, .3);
}

:is(.dark .dark\:bg-primary) {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 143, 151, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-slate-100) {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-slate-200) {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-white) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

:is(.dark .dark\:fill-\[\#666\]) {
  fill: #666;
}

:is(.dark .dark\:fill-black) {
  fill: #000;
}

:is(.dark .dark\:text-accent) {
  --tw-text-opacity: 1;
  color: rgba(0, 149, 233, var(--tw-text-opacity));
}

:is(.dark .dark\:text-black) {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-800) {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

:is(.dark .dark\:text-primary) {
  --tw-text-opacity: 1;
  color: rgba(132, 143, 151, var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

:is(.dark .group:hover .dark\:group-hover\:bg-gray-800\/60) {
  background-color: rgba(31, 41, 55, .6);
}

:is(.dark .group:focus .dark\:group-focus\:ring-gray-800\/70) {
  --tw-ring-color: rgba(31, 41, 55, .7);
}

:is(.dark .peer:focus ~ .dark\:peer-focus\:ring-accent) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 149, 233, var(--tw-ring-opacity));
}

@media (min-width: 640px) {
  .sm\:visible {
    visibility: visible;
  }

  .sm\:relative {
    position: relative;
  }

  .sm\:right-0 {
    right: 0;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:h-6 {
    height: 1.5rem;
  }

  .sm\:h-8 {
    height: 2rem;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:min-h-screen {
    min-height: 100vh;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-6 {
    width: 1.5rem;
  }

  .sm\:w-8 {
    width: 2rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:grid-rows-none {
    grid-template-rows: none;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .sm\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .sm\:pl-2 {
    padding-left: .5rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:tracking-normal {
    letter-spacing: 0;
  }
}

@media (min-width: 768px) {
  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-\[75\%\] {
    width: 75%;
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:gap-x-2 {
    column-gap: .5rem;
  }

  .md\:border-b {
    border-bottom-width: 1px;
  }
}

@media (min-width: 1024px) {
  .lg\:bottom-\[75px\] {
    bottom: 75px;
  }

  .lg\:bottom-\[98px\] {
    bottom: 98px;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-full {
    grid-column: 1 / -1;
  }

  .lg\:col-start-8 {
    grid-column-start: 8;
  }

  .lg\:col-end-13 {
    grid-column-end: 13;
  }

  .lg\:row-start-1 {
    grid-row-start: 1;
  }

  .lg\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:contents {
    display: contents;
  }

  .lg\:\!hidden {
    display: none !important;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[450px\] {
    height: 450px;
  }

  .lg\:max-h-\[450px\] {
    max-height: 450px;
  }

  .lg\:min-h-\[165px\] {
    min-height: 165px;
  }

  .lg\:min-h-\[450px\] {
    min-height: 450px;
  }

  .lg\:min-h-\[500px\] {
    min-height: 500px;
  }

  .lg\:min-h-\[77px\] {
    min-height: 77px;
  }

  .lg\:w-\[70px\] {
    width: 70px;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:min-w-\[210px\] {
    min-width: 210px;
  }

  .lg\:min-w-\[800px\] {
    min-width: 800px;
  }

  .lg\:max-w-\[12rem\] {
    max-width: 12rem;
  }

  .lg\:max-w-\[13rem\] {
    max-width: 13rem;
  }

  .lg\:max-w-\[34\%\] {
    max-width: 34%;
  }

  .lg\:max-w-\[65\%\] {
    max-width: 65%;
  }

  .lg\:max-w-\[800px\] {
    max-width: 800px;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:gap-x-4 {
    column-gap: 1rem;
  }

  .lg\:gap-x-6 {
    column-gap: 1.5rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-\[17px\] {
    font-size: 17px;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }
}

@media (min-width: 1280px) {
  .xl\:col-start-9 {
    grid-column-start: 9;
  }
}

/*# sourceMappingURL=index.css.map */
