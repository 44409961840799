@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Roboto, system-ui, sans-serif;
        font-size: 12px;
    }

    @media (min-width: 1024px) {
        html {
            font-size: 14px;
        }

        .search-box {
            max-width: 250px;
        }

        .featured-news {
            max-width: 250px;
            min-width: 250px;
        }
    }

    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }

    .scrollbar-hide {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.light {
    background: #fff;

}

.nav-item {
    @apply flex flex-col font-semibold text-xl lg:text-lg leading-4 pb-5;
}

.nav-item svg {
    @apply fill-[#aaa] h-6 w-6;
}

.nav-item svg.nav-large-icon {
    @apply fill-[#aaa] h-10 w-10;
}

.nav-item .active-line {
    @apply h-2 w-full bg-transparent rounded-b-lg mb-5;
}

.nav-item.menu {
    @apply flex flex-col font-semibold leading-6 pb-5 text-primary-lighter dark:text-black;
}

.nav-item.menu svg {
    @apply fill-primary-lighter dark:fill-black;
}

.nav-item.menu .active-line {
    @apply h-2 w-full bg-transparent rounded-b-lg mb-5;
}

.nav-item.active {
    @apply !text-accent;
}

.nav-item.active svg {
    @apply fill-accent;
}

.nav-item.active .active-line {
    @apply bg-accent;
}

@layer components {
    .tab-content {
        @apply border border-primary-darker dark:border-zinc-200 dark:border-zinc-200 rounded-lg lg:rounded-3xl p-2 lg:p-3;
    }

    .tab {
        @apply bg-main-bg-lighter dark:bg-slate-200 rounded-lg font-medium text-center px-4 py-2 w-full;
    }

    .tab.active {
        @apply bg-accent text-primary-lighter dark:bg-accent dark:text-primary-lighter;
    }

    .btn-list .active {
        @apply bg-accent text-primary-lighter;
    }
}

.sp-item-hover {
    @apply flex justify-between items-center w-full bg-main-bg-lighter dark:bg-slate-200 rounded-lg py-2 dark:text-black text-primary-lighter dark:text-black cursor-pointer hover:bg-[#333];
}

.sp-item-hover-light {
    @apply flex justify-between items-center w-full rounded-lg py-2 cursor-pointer hover:bg-[#ccc];
    background-color: #fff;
    color: '#000'
}

.sp-item {
    @apply flex justify-between items-center w-full bg-main-bg-lighter dark:bg-slate-200 rounded-lg py-2 text-primary-lighter dark:text-black;
}

.score {
    @apply flex flex-col sm:flex-row items-center justify-center bg-primary-darker font-semibold text-sm sm:text-base leading-4 min-w-[2rem] sm:min-w-[5rem] rounded-lg px-3 py-1 sm:py-2 mx-2 sm:mx-3 h-16 sm:h-auto order-last sm:order-none absolute sm:relative right-0 dark:bg-slate-100;
}

.score-full-nomargin {
    @apply flex items-center justify-center bg-primary-darker font-semibold text-sm sm:text-base leading-4 rounded-lg px-2 py-2.5 sm:px-2;
}

.tab-detail {
    @apply flex flex-col font-semibold text-base text-lg leading-6 pb-6 lg:pb-5 whitespace-nowrap;
}

.tab-detail .active-line {
    @apply h-2 w-full bg-transparent rounded-b-lg mb-3 lg:mb-4;
}

.tab-detail.active {
    @apply text-accent;
}

.tab-detail.active .active-line {
    @apply bg-accent;
}

.sp-item-time {
    @apply flex flex-col justify-between items-start w-full bg-main-bg-lighter dark:bg-white rounded-lg p-4 lg:p-6 text-primary-lighter dark:text-black dark:border dark:border-zinc-200;
}

.score-time {
    @apply flex flex-col items-center justify-center bg-primary-darker dark:bg-slate-400 font-semibold text-sm sm:text-base leading-4 px-3 sm:px-4 w-10 h-16;
}

.score-time-start {
    @apply flex flex-col items-center justify-center bg-primary-darker dark:bg-slate-400 font-semibold text-sm sm:text-base leading-4 px-3 sm:px-4 w-10 h-4;
}

.minute {
    @apply flex justify-center items-center bg-main-bg dark:bg-slate-500 rounded-full text-xs h-8 w-8 dark:text-slate-100;
}

.match-time {
    @apply flex flex-col items-center border border-primary-darker dark:border-zinc-200 dark:border-zinc-200 rounded-md py-0.5 px-8;
}

.action {
    @apply flex items-center border border-primary-darker dark:border-zinc-200 dark:border-zinc-200 rounded-md max-w-full h-10 px-3 overflow-hidden;
}

.label-check {
    @apply flex items-center text-xs text-primary-lighter dark:text-black bg-primary rounded px-1 py-0.5;
}

.bg-bottom-shadow::after {
    @apply content-[""] absolute top-[70px] left-[5px] w-[250px] h-[70px];
    background: linear-gradient(180deg, hsla(0, 0%, 4%, 0) 0, #111) no-repeat;
}

.ellipse-content {
    @apply overflow-hidden text-ellipsis w-full;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


#scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    @apply h-[3px] bg-[#0D1116];
}

#scrollbar::-webkit-scrollbar {
    @apply h-[3px] md:h-[8px] bg-[#0D1116];
}

#scrollbar::-webkit-scrollbar-thumb {
    @apply bg-[#47494b] rounded-lg;
}

.btn-actived {
    @apply bg-[#222];
}

.btn-actived a {
    @apply text-accent;
}

.swiper-button-disabled {
    display: none !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 12px !important;
    color: #fff;
}

.imagecontainer table {
    @apply w-full;
}

.news-wrapper .swiper::after,
.news-wrapper .swiper::before {
    z-index: 5;
    content: "";
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
}

.news-wrapper .swiper::after {
    right: 0;
    background-image: linear-gradient(270deg, #111, hsla(0, 0%, 4%, 0));
    @apply dark:bg-none
}

.news-wrapper .swiper::before {
    left: 0;
    background-image: linear-gradient(90deg, #111, hsla(0, 0%, 4%, 0));
    @apply dark:bg-none
}

.news-content img {
    @apply mx-auto mt-4 rounded-xl;
}

.news-content p {
    @apply my-2;
}

.news-content h2 {
    @apply text-[#fdfdfd] dark:text-black font-bold text-[18px] italic mt-3;
}

.news-content .summary {
    @apply italic mb-2;
}

.news-content .imagecontainer,
.news-content .wp-block-image {
    @apply text-center italic;
}

.news-content .image {
    @apply text-center italic;
}

.news-content .sapo_detail {
    @apply font-bold italic;
}

.news-content .expNoEdit {
    @apply text-center italic;
}

.virtuoso-grid-item {
    @apply md:w-1/2 w-full inline-block;
}

figcaption.e-caption.u-meta.e-caption--has-separator {
    @apply block text-center italic mb-5 leading-none;
}

figcaption.e-caption.u-meta.e-caption--has-separator span {
    @apply leading-[15px];
}

figcaption.e-caption.u-meta.e-caption--has-separator p {
    @apply m-0;
}

figcaption.e-caption.u-meta.e-caption--has-separator .e-caption__credit {
    @apply !leading-[5px];
}

.news-img>span.lazy-load-image-background.blur.lazy-load-image-loaded {
    @apply h-full !block flex-[0_0_130px] !w-auto !h-full;
}

.news-slider>a>span.lazy-load-image-background.blur.lazy-load-image-loaded {
    @apply h-full !block flex-[0_0_130px] !w-auto !h-full;
}

.highlights-img>span.lazy-load-image-background.blur {
    @apply h-full !block flex-[0_0_250px] !w-auto !h-full;
}

.highlight-related>.lazy-load-image-background.blur.lazy-load-image-loaded {
    @apply h-full w-full;
}

.highlight-related>.lazy-load-image-background.blur img {
    @apply !w-full;
}

.highlights-img span.lazy-load-image-background.blur img {
    @apply !w-full;
}

.news-related>.lazy-load-image-background.blur img {
    @apply !w-full;
}

.news-related>.lazy-load-image-background.blur.lazy-load-image-loaded {
    @apply h-full w-full;
}

.featured-news-item .lazy-load-image-background.blur {
    @apply !w-full;
}

.show-on-hover {
    @apply block;
}

.play-icon-animation {
    background-image: linear-gradient(125deg, rgb(218, 148, 19), #2196f3);
    animation: bganimation 1s infinite;
    background-size: 400%;
}

/* span.lazy-load-image-background.blur.lazy-load-image-loaded {
    @apply h-full !block flex-[0_0_130px];
} */

#bottom-menu {
    @apply sticky bottom-0 text-[#aaa] cursor-pointer flex flex-row m-auto text-center no-underline w-full z-20 border-t border-[#222] dark:border-zinc-300;
}

#bottom-menu .nav-item {
    @apply cursor-pointer flex flex-col m-auto text-center no-underline w-[25vw] whitespace-nowrap mt-[-1px];
}

@keyframes bganimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}